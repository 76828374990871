<template>
    <div v-if="data.timeline">
        <span v-if="data.currentAssign && data.currentAssign.absence_type">

            {{data.currentAssign.display}}
        </span>

        <template v-else>
            <template v-if="data.currentAssign && data.currentAssign.non_working_day && !data.currentAssign.force_working_day">
                <button class="btn btn-light" @click="setWorkingDay(data.timeline[timeId])">Uputi</button>
            </template>
            
            <template v-else>
                <select class="form-control" name="employeeWO" v-model="data.currentAssign.work_order" @focus="oldValue = data.currentAssign.work_order;" @change="changeSchedule($event, oldValue)" >
                    <option disabled :value="undefined">Izaberite nalog</option>
                    <option v-if="user.role==='ADMIN'" :value="null">Neradni dan</option>
                    <option v-if="data.currentAssign.work_order && user.role==='ADMIN'" :value="-1">Izmeni ulogu</option>
                    <template v-for="wo in allWorkOrders">
                        <option v-if="wo.id === data.currentAssign.work_order || !wo.real_end_date || wo.real_end_date > dateProp" :value="wo.id" :key="wo.id">{{rolePrefix}}{{ wo.name }}</option>
                    </template>
                </select>
                <small :title="'Izmenjano: ' + formatDateTime(data.currentAssign.created_at)" v-if="data.currentAssign.manager_changed">{{data.currentAssign.created_by_obj.first_name}} {{data.currentAssign.created_by_obj.last_name}}</small>
            </template>
        </template>
    </div>
</template>

<script>
import UtilService from '@/service/UtilService.js';
import { Modal } from 'bootstrap';

import {mapState} from 'vuex';

import Vue from 'vue';

Vue.prototype.$eventHub = new Vue(); 

export default {
    name: 'grid-date-time-emp-assignment-template',

    data: function() {
        return {
            data: {},
            modalConfirm: null,
            oldValue: null,

            working: null,
        }
    },

    props: {
        timeId: Number,
        dateTimeSeconds: String,
    },

    computed: {
        ...mapState([
            'user',
            'allWorkOrders',
        ]),


        rolePrefix() {
            return this.data.currentAssign.pwo_role == 1?"(M) ":"";
        },

        dateProp() {
            return this.dateTimeSeconds.slice(0,10);
        },
    },

    mounted() {
        this.modalConfirm = new Modal(document.getElementById('confirmModal'));
    },


    methods: {

        formatDatePattern: UtilService.formatDatePattern,
        formatDateTime: UtilService.formatDateTime,
        absenceTypeFormat: UtilService.absenceTypeFormat,

        changeSchedule(event, oldValue) {
            if (this.data.currentAssign.non_working_day && this.data.currentAssign.work_order === null){
                this.removeWorkingDay(this.data.currentAssign);
                return;
            }

            let showModal = this.data.currentAssign.work_order==-1
            if (this.data.currentAssign.work_order==-1) this.data.currentAssign.work_order = oldValue;

            let workOrderId = this.data.currentAssign.work_order==-1?oldValue:this.data.currentAssign.work_order
            let newScheduleData = {
                'employee': this.data.id,
                'work_order': workOrderId,
                'date': this.dateTimeSeconds,
                'pwo_role': this.data.currentAssign.pwo_role
            }
            console.log(newScheduleData);
            this.$parent.$parent.setScheduleEvent(newScheduleData, showModal)
            // this.$eventHub.$emit('setScheduleData', newScheduleData)
        },

        removeWorkingDay(){
            let empDayStatus = {
                employee_id: this.data.id,
                date: this.dateTimeSeconds.slice(0,10),
            }

            this.$parent.$parent.deleteWorkingDay(empDayStatus, this.data.currentAssign);


        },

        setWorkingDay(){
            let empDayStatus = {
                employee_id: this.data.id,
                working: true,
                date: this.dateTimeSeconds.slice(0,10),
                deleted: false,
            }
            this.$parent.$parent.setWorkingDay(empDayStatus, this.data.currentAssign);

        }

    }
}
</script>

