<template>
    <div class="modal fade" :id="inputId" tabindex="-1" :aria-labelledby="inputId + 'Label'" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Upućivanje radnika <small>na dan {{dateTimeFormated}}</small></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body" v-if="employee && assignData">
                    <div class="user-header mb-5">
                        <div class="d-flex">
                            <img :src="getAvatarUrl(employee)" class="rounded-circle avatar" width="290"/>
                            <h3>{{userFullName(employee.user)}}</h3>

                        </div>
                    </div>

                    
                    <div class="transfer-box mb-5">
                        <div class="start-position">
                            <h4 class="pos-name mb-0">
                                <template v-if="empCurrentAssignment">{{empCurrentAssignment.name}}</template>
                                <template v-else>/</template>    
                            </h4>
                        </div>
                        <div class="arrow ps-2 pe-2">
                            <font-awesome-icon icon="arrow-right" size="3x" />
                        </div>
                        <div class="end-position center-text-flex">
                            <select class="form-control" v-model="assignData.work_order" name="employeeWO" >
                                <option :value="null">/</option>            
                                <option v-for="wo in allActiveWorkOrders" :value="wo.id" :key="wo.id">{{ wo.name }}</option>
                            </select>
                        </div>
                    </div>

                    <h5>Uloga zaposlenog:</h5>
                    <div class="role-choice">
                        <input :disabled="assignData.work_order == null" type="radio" class="btn-check" name="options-outlined" :value="0" v-model="assignData.pwo_role" id="worker-role-choice" autocomplete="off" checked>
                        <label class="btn btn-outline-primary btn-radio" for="worker-role-choice">Radnik <font-awesome-icon icon="hard-hat" /></label>

                        <input :disabled="assignData.work_order == null" type="radio" class="btn-check" name="options-outlined" :value="1" v-model="assignData.pwo_role" id="manager-role-choice" autocomplete="off">
                        <label class="btn btn-outline-primary btn-radio" for="manager-role-choice">Menadžer <font-awesome-icon icon="user-tie" /></label>
                    </div>
                </div>

                <div class="modal-footer">
                    <button @click="$emit('close')" type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Zatvori </button>
                    <button @click="$emit('confirm')" type="button" class="btn btn-primary" data-bs-dismiss="modal"> Potvrdi </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import UtilService from '@/service/UtilService.js';


    export default {

        props: {
            inputId: String,
            employee: Object,
            selectedDate: String,
            assignData: Object,
        },


        computed: {
            ...mapState([
                'allWorkOrders',
            ]),

            dateTimeFormated() {
                return UtilService.formatDateAndOrTime(this.selectedDate)
            },

            empCurrentAssignment() {

                return this.employee?.currentAssign?.work_order_obj;
            },

            todayDate() {
                return UtilService.formatDatePattern(UtilService.getMoment())
            },

            allActiveWorkOrders() {
                return this.allWorkOrders.filter(x => !x.real_end_date || x.real_end_date > this.todayDate)
            },
        },

        methods: {        

            userFullName: UtilService.userFullName,

            getAvatarUrl(employee) {
                return employee?.user?.avatar_url?employee.user.avatar_url:require('@/assets/profilna.png')
            }
        }

    }
</script>

<style scoped>

    .start-position {
        display: flex;
        align-content: center;
        flex-wrap: wrap;
    }

    .start-position .pos-name {
        background: #ced4da;
        border-radius: 0.25rem;
        padding: 0.3rem;
        font-size: 1.4rem;
    }

    .transfer-box{
        display: flex;
        justify-content: space-around;
        align-content: center;
    }

    .transfer-box .arrow {
        height: 100%;
        vertical-align: middle;
    }

    .end-position {
        max-width: 55%;
    }

    .end-position .form-control {
        font-size: 1.2em;
        font-weight: 700;
    }

    .user-header .avatar {
        width: 50px;
        height: 100%;

    }

    .center-text-flex {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
    }

    .role-choice {
        display: flex;
    }

    .role-choice label.btn {
        width: 100%;
    }

    .role-choice .btn-radio {
        padding: 1em;
    }

</style>

