import axios from "axios";
import config from "@/config";

const API_URL = config.apiUrl + config.apiUrlPrefix;

class CalendarService {


    getNonWorkingDays(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/calendar/non-working-days`,
            params: data,
        })
    }

    updateNonWorkingDays(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/calendar/non-working-days`,
            data: data,
        })
    }

    getEmployeeDayStatus(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/employees/` + data.employee_id + `/day-status`,
            params: data,
        })
    }

    updateEmployeeDayStatus(data) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/employees/` + data.employee_id + `/day-status`,
            data: data,
        })
    }

    deleteEmployeeDayStatus(data) {
        return axios({
            method: 'DELETE',
            url: `${API_URL}/employees/` + data.employee_id + `/day-status`,
            data: data,
        })
    }


    getEmployeesDayStatuses(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/employees/day-status`,
            data: data,
        })
    }

}

export default new CalendarService();